<template>
  <v-container>
    <div class="text-h1">
      Page not found
    </div>
    <div class="mt-12">
      Sorry, this page doesn't exist.
    </div>
  </v-container>
</template>


<script>

export default {
  name: "PageNotFound",
  components: {},
  props: {},
  data() {},
}
</script>


<style scoped lang="scss">

</style>